'use strict'

export function carSearchermobile_backHTML () {
    document.querySelector('.carsearcher-content').insertAdjacentHTML(
      "beforeend",
      `<div class = "banner-search banner banner-back">
  назад
  </div>
  `
    );
  };
  
  